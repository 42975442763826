import { Outlet } from 'react-router-dom'
import {
  PageHome,
  Page404,
  PageContact,
  PageBrochures,
  PageProducts,
  PageTermsAndPolicies,
} from 'screens/index'

import { Header, Footer } from 'organisms'

function ViewWithHeaderAndFooter() {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

export default function routes() {
  return [
    {
      path: '/',
      element: <ViewWithHeaderAndFooter />,
      children: [
        {
          path: '/',
          element: <PageHome />,
        },

        {
          path: '/products',
          element: <PageProducts />,
        },

        {
          path: '/brochures',
          element: <PageBrochures />,
        },
        {
          path: '/products/:targetName',
          element: <PageProducts />,
        },
        {
          path: '/contact',
          element: <PageContact />,
        },
        {
          path: '/terms-and-policies',
          element: <PageTermsAndPolicies />,
        },
        {
          path: '*',
          element: <Page404 />,
        },
      ],
    },
  ]
}
