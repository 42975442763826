import React, { useEffect, useReducer, useState } from 'react'
import Card from './card'
import { getBrochures } from 'hooks/useSupabase'

export default function List() {
  const [data, setData] = useState<any[]>([{}, {}, {}])
  const [isLoading, endLoading] = useReducer(() => false, true)
  const [isError, setError] = useState(false)

  useEffect(() => {
    const getList = async () => {
      const { data, isSuccess } = await getBrochures('brochures')
      if (isSuccess) {

        endLoading()
        setData(data)
      } else {
        setError(true)
      }
    }
    getList()
  }, [])
  if (isLoading)
    return (
      <div className="min-h-[300px] grid place-content-center text-center px-10">
        <span className="text-dark font-medium text-2xl">Loading...</span>
      </div>
    )
  if (isError)
    return (
      <div className="min-h-[300px] grid place-content-center text-center px-10">
        <span className="text-dark font-medium text-2xl">
          Error loading data. Please try again later.
        </span>
      </div>
    )

  if (data.length > 0){ console.log('data', data)}
  return (
    <div className="md:px-6 px-3 lg:px-8 xl:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-container gap-x-4 gap-y-6 blade-top-padding-lg blade-bottom-padding-lg">
        {data.map((elem, index) => {
          return <Card isLoading={isLoading} elem={elem} key={`${index}`} />
        })}
      </div>
    </div>
  )
}
