import { createClient } from '@supabase/supabase-js'

// Create a single supabase client for interacting with your database
const client = createClient(
  'https://jpohbxuntgkcrwyhpqjo.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Impwb2hieHVudGdrY3J3eWhwcWpvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDMzNTY0MjIsImV4cCI6MjA1ODkzMjQyMn0.LpS9rPrfzyTdqsu6a7GK4TbtXiRecowLI5Mo6ThUI_g',
)

const Supabase = {
  client,
}

export default Supabase
