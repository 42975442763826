const sizeMap = {
  small: 'text-sm px-4 py-1.5',
  base: 'text-base px-5 py-2.5',
  large: ' text-base md:text-lg 2xl:text-xl px-7 md:px-10  pt-2.5 pb-2 ',
  xl: ' text-base md:text-xl',
  xxl: ' text-xl xl:text-2xl',
  '3xl': ' text-xl md:text-2xl xl:text-3xl',
}

type MasterBtnProps<T extends 'submit' | 'button'> = {
  text: string
  size: keyof typeof sizeMap
  type: T
  isDisabled?: boolean
  onClick?: T extends 'submit' ? never : () => void
}

const colorMap = {
  dark: 'text-slate-100 bg-slate-900',
  light: 'text-black text-opacity-90 bg-slate-100',
}

export function MasterBtn<T extends 'submit' | 'button'>({
  type,
  text,
  size,
  color = 'light',
  isDisabled,
  ...rest
}: MasterBtnProps<T> & { color?: 'dark' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <button
      disabled={isDisabled}
      type={type === 'button' ? 'button' : 'submit'}
      {...rest}
      className={`${sizeClass}  disabled:opacity-70 focus-visible:outline-dark hover:outline-dark outline-transparent outline  outline-offset-2 disabled:pointer-events-none disabled:touch-none ${colorClass} group font-semibold stroke-slate-80 outline-none focus-visible:outline-none rounded-full flex items-center gap-5 `}
    >
      {text}
    </button>
  )
}
