import { ReactNode } from 'react'
import { NavLink, Link } from 'react-router-dom'

const sizeMap = {
  small: 'text-sm 2xl:text-lg py-2 px-3 md:px-4 ',
  base: 'text-base 2xl:text-xl px-4 md:px-6 py-3',
  large: ' text-base md:text-lg 2xl:text-xl  px-4 md:px-6 py-3 ',
  xl: ' text-base md:text-xl',
  xxl: ' text-xl xl:text-2xl',
  '3xl': ' text-xl md:text-2xl xl:text-3xl',
}

const colorMap = {
  dark: 'text-black',
  light: 'text-black text-opacity-90 bg-slate-100',
}

type MasterAnchorProps = {
  size?: keyof typeof sizeMap
  text: string
  href: string
  name?: string
  className?: string
  target?: '_self' | '_blank'
}

export function TextAnchor({
  text,
  target,
  href,
  name,
  className = '',
  color = 'dark',
  size = 'base',
  ...rest
}: MasterAnchorProps & { color?: 'dark' | 'light' }) {
  return (
    <Link
      target={target}
      to={href}
      {...rest}
      className={` font-light  ${className}`}
    >
      {name && <span className="sr-only">{name} </span>}
      {text}
    </Link>
  )
}
export function TextNavAnchor({
  text,
  target,
  href,
  color = 'dark',
  size = 'base',
  ...rest
}: MasterAnchorProps & { color?: 'dark' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <NavLink
      target={target}
      to={href}
      {...rest}
      className={(navData) => {
        return `${navData.isActive ? '' : ''}  ${sizeClass}  ${colorClass}`
      }}
    >
      {text}
    </NavLink>
  )
}

export function MasterAnchor({
  text,
  size = 'base',
  href,
  target,
  name,
  color = 'dark',
  ...rest
}: MasterAnchorProps & { color?: 'dark' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]

  return (
    <Link
      to={href}
      target={target}
      {...rest}
      className={`${sizeClass}  ${colorClass} group font-semibold stroke-slate-80 outline-none focus-visible:outline-none rounded-lg flex items-center gap-5 `}
    >
      {text}

      {name && <span className="sr-only">{name} </span>}
      <div
        className={`grid  ${
          color === 'light' ? 'bg-slate-800 ' : 'bg-slate-100'
        }  h-6 w-6 md:h-9 md:w-9 place-content-center p-1 rounded-full transition-colors duration-300 ease-in-out group-hover:bg-orange-700 group-focus-visible:bg-orange-700`}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          stroke={color === 'light' ? '#ebeff3' : '#2a3440'}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 7H13"
            stroke="inherit"
            strokeWidth="1.61044"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 1L13 7L7 13"
            stroke="inherit"
            strokeWidth="1.61044"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </Link>
  )
}

export function Anchor({
  href,
  children,
  className = '',
}: {
  children: ReactNode
  className?: string
  href: string
}) {
  return (
    <Link className={className} to={href}>
      {' '}
      {children}
    </Link>
  )
}

export function ExternalAnchor({
  href,
  children,
  name,
  className,
}: {
  href: string
  className?: string
  name?: string

  children: ReactNode
}) {
  return (
    <a
      className={className}
      href={href}
      title={name}
      aria-label={name}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="sr-only">{name} </span>
      {children}
    </a>
  )
}
