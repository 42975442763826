import check from "assets/pdf_view.png"

interface IElem {
  url: string
  filename: string
  size: string
}
export default function Card({
  elem,
  isLoading,
}: {
  isLoading: boolean
  elem: IElem
}) {
  const { url, filename, size } = elem

  return (
    <a
      target="_blank"
      href={url}
      className="max-w-[350px] hover:bg-darkcream transition-all duration-150 ease-in-out h-full lg:max-w-none mx-auto w-full"
    >
      <div className="p-2 flex  gap-4  h-full  border-2 border-solid border-brown border-opacity-30 rounded-lg">
        <div>
          <div className="border-1 border-solid border-brown w-12 h-12  rounded flex justify-center items-center">
            {/* <span className="flex text-white font-bold">PDF</span> */}
            <img src={check} className='w-full h-full object-center object-cover' />
          </div>
        </div>
        <div className="flex flex-col gap-px">
          <span className="text-lg xl:text-lg font-medium text-dark">
            {filename}{' '}
          </span>
          <span className="text-dark text-opacity-80 text-[12px]">
            {' '}
            {size} MB{' '}
          </span>
        </div>
      </div>
    </a>
  )
}
