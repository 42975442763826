import Supabase from '../lib/supabase'

interface IBrochure {
  url: string
  filename: string
  size: string
  type: 'video' | 'image'
}

function bytesToMB(bytes: number): string {
  const mb = bytes / (1024 * 1024)
  return mb.toFixed(2)
}

function formatFilename(filename: string): string {
  let formattedName = filename.replace(
    /\.(pdf|jpg|jpeg|png|gif|webp|bmp|tiff|svg)$/i,
    '',
  )
  return formattedName
}

function getDownloadURL(folder: string, fileRef: any): string | null {
  try {
    const { data } = Supabase.client.storage
      .from('assets')
      .getPublicUrl(`${folder}/${fileRef.name}`)

    return data.publicUrl
  } catch (error) {
    console.log(`failed to fetch public URL for the brochure: ${fileRef.name}`)
    return null
  }
}

async function getFilesInfo(folder: string): Promise<IBrochure[]> {
  try {
    const { data: fileList } = await Supabase.client.storage
      .from('assets')
      .list(folder)

    const brochures: IBrochure[] = !fileList
      ? []
      : (fileList.map((fileRef) => {
          const metadata = fileRef.metadata
          const url = getDownloadURL(folder, fileRef)
          const formattedFilename = formatFilename(fileRef.name)
          const sizeInMB = bytesToMB(metadata.size)
          const type = String(metadata.mimetype ?? '').startsWith('video')
            ? 'video'
            : 'image'
          return {
            url,
            filename: formattedFilename,
            size: sizeInMB,
            type,
          }
        }) as unknown as IBrochure[])
    return brochures
  } catch (error) {
    console.error('Error retrieving files:', error)
    return [] as IBrochure[]
  }
}

export const getBrochures = async (folder: string): Promise<{
  data: IBrochure[]
  isSuccess: boolean
}> => {
  try {
    const data = await getFilesInfo(folder)
    return { data, isSuccess: true }
  } catch (error) {
    console.log('failed to fetch the brochures')
    return { data: [], isSuccess: false }
  }
}
